
  import { Component, Prop } from 'vue-property-decorator'
  import Cell from './cell'
  import { PublishPlatformOptions } from '@/components/dataTables/cell/index'
  import { Platform } from '@/entities/public'
  import { StockAdvertiser } from '@/entities/sales'
  import { Helpers } from '@/views/datatables/resources/helpers'
  import { mapActions } from 'vuex'

@Component({
  methods: {
    ...mapActions('resources/form', ['publishByPlatform', 'publishStock']),
  },
})
  export default class PublishPlatformCell extends Cell {
  @Prop({ type: Boolean, default: true }) displaySpanName!: boolean
  declare options: PublishPlatformOptions
  publishStock!: (stockId: number) => Promise<void>
  publishByPlatform!: (variables) => Promise<void>

  platform: Platform = undefined
  stock: StockAdvertiser = this.item
  showPostNotification: boolean = false

  async mounted () {
    const { platformName } = this

    this.platform = (await this.fetchData({
      query: { name: 'find', model: 'Platform' },
      filter: {
        name: { _ilike: `%${platformName}%` },
      },
    }))[0]
  }

  get isSold () {
    return this.stock.status.isSold
  }

  get isRetired () {
    return this.stock.status.isRetired
  }

  get platformName () {
    return this.options.platformName
  }

  get stockPlatform () {
    const { platformName } = this
    const publishPlatformsWithPost = this.stock?.publishPlatformsWithPost

    return publishPlatformsWithPost?.find(platform => platform.name.toLocaleLowerCase() === platformName)
  }

  get platformEnabled () {
    const { stockPlatform } = this

    return Boolean(stockPlatform?.enabled)
  }

  get isPublished () {
    const { stockPlatform, stock, platformName } = this

    return stockPlatform?.published || Boolean(stock[platformName])
  }

  get hasPlatformsMetadata () {
    const { stock } = this

    return Boolean(stock.metadata?.publishPlatforms && stock.metadata.publishPlatforms?.length)
  }

  get forceDisable () {
    const { stock } = this

    return stock.id !== stock.carDetail?.idStock
  }

  get buttonData () {
    const { isPublished, platformEnabled, stock, stockPlatform, platformName, isSold, isRetired, hasPlatformsMetadata, platform, forceDisable } = this

    if (!isPublished || forceDisable) {
      if ((!platformEnabled && hasPlatformsMetadata) || isSold || isRetired || forceDisable) {
        return {
          icon: 'mdi-web-off',
          color: undefined,
          tooltip: 'Plataforma deshabilitada para este vehículo y/o vehículo vendido',
          disabled: true,
          handler: () => {},
        }
      } else {
        return {
          icon: 'mdi-web-remove',
          color: 'red',
          tooltip: 'Reintentar publicación',
          disabled: false,
          notification: `Reintentando publicar el vehículo ${stock.auto.name} en la plataforma ${String(platformName).charAt(0).toUpperCase() + String(platformName).slice(1)}. El cambio puede tomar unos minutos en verse reflejado.`,
          handler: async () => {
            this.showPostNotification = true
            await this.publishByPlatform({
              id_stock: stock.id,
              id_platform: stockPlatform?.id || platform.id,
            })
          },
        }
      }
    }

    if (isPublished) {
      if (platformEnabled || !hasPlatformsMetadata) {
        return {
          icon: 'mdi-web',
          color: 'primary',
          tooltip: 'Ver publicación',
          disabled: false,
          handler: () => Helpers.portal(stockPlatform?.post || stock?.[platformName]),
        }
      } else if (!platformEnabled && hasPlatformsMetadata && !(isRetired || isSold)) {
        return {
          icon: '$web_warning',
          color: 'warning',
          tooltip: 'Despublicar para plataforma deshabilitada',
          disabled: false,
          notification: `Enviando a despublicar el vehículo ${stock.auto.name} en la plataforma ${String(platformName).charAt(0).toUpperCase() + String(platformName).slice(1)}. El cambio puede tomar unos minutos en verse reflejado.`,
          handler: async () => {
            this.showPostNotification = true
            await this.publishStock(stock.id)
          },
        }
      }
    }
  }

  get position () {
    const { item } = this
    if (!item) return 'top'
    const { position = 'top' } = item
    return position
  }

  get top () {
    const { position } = this

    return position === 'top'
  }

  get left () {
    const { position } = this

    return position === 'left'
  }
  }
